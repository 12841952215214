.menu-rodape{
	padding-left:0;
	list-style-type:none;
	margin-bottom:15px;
	position:relative;
	text-align:center;

	& > li{

		display:block;

		& > a{
			display:block;
			color:#FFFFFF;
			font-size:21px;
			@extend .lato-lgt;
			padding:15px 5px 10px 5px;
		}

		&:hover > a,
		&.active > a{
			@extend .lato-bld;
		}

	}

	@include media-breakpoint-up(md){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-around;
	}

}