@include media-breakpoint-down(lg){

	.topo-info{

		.logo{
			max-width:45%;
			width:300px;
			margin-left:auto;
			margin-right:auto;
			margin-bottom:25px;
		}

		.dados{

			.idioma{
				display:block;
				text-align:center;
				display:none;
			}

			& > .d-flex{
				display:none !important;
			}

		}

	}

	.topo-bar{

		& > .nav-content{
			position:fixed;
			top:0;
			left:-300px;
			width:250px;
			height:100%;
			z-index:1000;
			transition:left 0.6s ease-in;
			background-color:$topo-bar;
			border-right:1px solid darken($theme,10%);
			overflow:auto;

			.logo{
				max-width:240px;
				margin-left:auto;
				margin-right:auto;
				padding:30px;
			}

		}

		&.ativado{

			.bg-menu{
				opacity:1;
				height:100%;
				z-index:998;
			}

			.nav-content{
				left:0;
			}

		}

	}

}