.redes-sociais{
	font-size:43px;

	& > .fa,
	& > .icon{
		margin:0 8px;

		&:first-child{
			margin-left:0;
		}

		&:last-child{
			margin-right:0;
		}

	}

	&.redes-sociais-white{

		.fa{
			color:#FFFFFF;
		}

	}

}