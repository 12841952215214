a{
	color: inherit;
	text-decoration:none;
	transition:all 0.6s ease-out;

	&:hover,
	&:focus{
		text-decoration:none;
	}

}

.link-hover-custom{

	& > a:hover,
	& > a:focus{
		color:$theme2;
	}

}

.internas img{
	@include img-fluid();
}

// USED FONTS
.lato-lgt{
	font-family: 'latolight';
}

.lato-reg{
	font-family: 'latoregular';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-blk{
	font-family: 'lato-black';
}

.lato-med{
	font-family: 'latomedium';
}

.mnt-lgt{
	font-family: 'montserratlight';
}

.mnt-bld{
	font-family: 'montserratbold';
}

body{
	font-size:14px;
	color:#000000;
	@extend .lato-reg;
}