.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 170px 133px;
	
	&.icon-arrow-left{
		width: 10px;
		height: 16px;
		background-position: -145px -65px;
	}
	&.icon-arrow-right{
		width: 10px;
		height: 16px;
		background-position: -145px -39px;
	}
	&.icon-br{
		width: 27px;
		height: 18px;
		background-position: -78px -106px;
	}
	&.icon-conversation{
		width: 29px;
		height: 29px;
		background-position: -53px -53px;
	}
	&.icon-en{
		width: 27px;
		height: 18px;
		background-position: -106px -78px;
	}
	&.icon-engenheiro{
		width: 29px;
		height: 29px;
		background-position: -106px -39px;
	}
	&.icon-facebook{
		width: 43px;
		height: 43px;
		background-position: 0px 0px;
	}
	&.icon-instagram{
		width: 43px;
		height: 43px;
		background-position: 0px -53px;
	}
	&.icon-linkedin{
		width: 43px;
		height: 43px;
		background-position: -53px 0px;
	}
	&.icon-msg-fechada{
		width: 29px;
		height: 21px;
		background-position: -39px -106px;
	}
	&.icon-msg{
		width: 29px;
		height: 27px;
		background-position: 0px -106px;
	}
	&.icon-pessoa{
		width: 25px;
		height: 29px;
		background-position: -145px 0px;
	}
	&.icon-telefone{
		width: 29px;
		height: 29px;
		background-position: -106px 0px;
	}
}


