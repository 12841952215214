.input-group-feedback{

	.form-control{
		height:auto;
		border:none;
		padding-left:0;
	}

	.input-group-prepend{
		margin-right:0;
		position:relative;
		z-index:5;
		min-height:38px;

		.input-group-text{
			border:none;
			background-color:#FFFFFF;
			padding-top:5px;
			padding-left:5px;
			padding-right:10px;

			& > *{
				align-self:center;
			}

		}

	}

	&.input-group-textarea{

		.input-group-text{

			& > *{
				align-self:flex-start;
			}

		}

	}

}