.mbl-controls{

	.idioma{
		margin-bottom:0;
		align-self:center;

		.idioma-iframe{
			display:none;
		}

	}

}

.idioma{
	margin-bottom:25px;

	.trocar-idioma{

		.icon{
			margin:0 5px;

			&:first-child{
				margin-left:0;
			}

			&:last-child{
				margin-right:0;
			}

		}

	}

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:flex-end;

		.trocar-idioma{
			padding-left:35px;
		}

	}

}