.form-busca{

	form{

		.input-group-prepend{
			margin-right:0;
		}

	}

	@include media-breakpoint-up(xl){

		&{

			form{
				min-width:((390 * 100) / 1110) * 1%;

				.form-control{
					min-height:56px;
				}

				.btn{
					padding:10px 22px;
					font-size:21px;
				}

			}

		}

	}

	@include media-breakpoint-down(lg){

		form{
			margin-bottom:30px;
			max-width:200px;
			margin-left:auto;
			margin-right:auto;
		}

	}

}

.form-busca-estilos{

	.form-control{
		background-color:$theme2;
		color:#FFFFFF;
		border:none;

		@include placeholdIt{
			color:#FFFFFF;
			font-size:14px;
			@extend .lato-lgt;
			font-style:italic;
		}

	}
	
}