.bar-maravilhosa{
	display:inline-block;
	min-width:94px;
	min-height:6px;
	margin-bottom:20px;
	margin-left:-15px;

	&.bar-theme{
		background-color:$theme;
	}

	&.bar-white{
		background-color:#FFFFFF;
	}

	@include media-breakpoint-up(lg){
		margin-left:-30px;
	}

}