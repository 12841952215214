.banner{
	width:1920px;
	max-width:100%;
	margin-left:auto;
	margin-right:auto;

	.carousel-item{
		@extend .figure-controlada;
	}

}

.sobre{
	padding-top:40px;
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
	background-attachment:fixed;
}

.gerais{
	background-color:#f6f6f6;
	padding-top:35px;
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
	background-attachment:fixed;
}

.atendimento{
	padding-top:40px;
}

.fale{
	background-color:$theme;
	color:#FFFFFF;
	padding-top:20px;
	padding-bottom:25px;
}

.novidades{
	padding-top:20px;
}

.internas{
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
	background-position:center center;
	padding-top:35px;

	&.internas-quem{

		.header-section{
			margin-bottom:35px;
		}

	}

	&.internas-produtos,
	&.internas-servicos{

		.produtos,
		.servicos{
			padding-bottom:40px;
		}

	}

	&.internas-blog{

		.blog{
			padding-bottom:60px;
		}

	}

	&.internas-blog-detalhes{

		.blog-detalhes{
			padding-bottom:150px;
		}

	}

	&.internas-contato{
		padding-top:0;
	}

	&.internas-listagem{

		.listagem{
			padding-bottom:37px;
		}

	}

}