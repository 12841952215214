.paginacao-custom{
	@extend .mnt-bld;

	.page-item{
		margin:5px 10px;

		&:first-child{
			margin-left:0;
		}

		&:last-child{
			margin-right:0;
		}

		.page-link{
			border-radius:100%;
			min-width:47px;
			text-align:center;
			padding:14px 0;
			background-color:#395d91;
			color:#FFFFFF;
			border-color:#395d91;
		}

		&.active,
		&:hover{

			.page-link{
				background-color:$theme;
				border-color:$theme;
			}

		}

	}

}