.comentario{
	min-height:292px;
	color:#231f20;
	padding:25px 25px 30px 25px;
	margin-bottom:30px;

	&:nth-child(odd){
		background-color:#f5f5f5;
	}

	&:nth-child(even){
		background-color:#FFFFFF;
	}

	.comentario-head{
		border-bottom:1px solid #FFFFFF;
		margin-bottom:25px;

		& > *{
			margin-bottom:25px;
		}

		.comentario-title{
			font-size:16px;
			@extend .lato-blk;
		}

		.comentario-dados{
			@extend .mnt-lgt;
			font-size:13px;
		}

	}

	.comentario-content{
		font-size:14px;
		@extend .lato-reg;
		font-style:italic;
		margin-bottom:20px;
	}

	.comentario-btn{
		text-align:right;
	}

	@include media-breakpoint-up(md){

		padding:25px 95px 30px 95px;

		.comentario-head{
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
		}

	}

}