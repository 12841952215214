@include media-breakpoint-up(xl){

	.nav-content{
		padding-left:30px;
		padding-right:30px;
		margin-left:auto;
		margin-right:auto;
		width:100%;
		max-width:1170px;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}

	.logo{
		max-width:425px;
	}

	.logo-escondida{
		display:none;
	}

	.topo-info{

		.logo{
			padding-left:10px;
		}

		.dados{
			padding-left:80px;
			flex-grow:1;

			.d-flex{

				& > *{
					margin-bottom:60px;
				}

			}

		}

	}

	.topo-bar{
		position:relative;

		.menu-topo{
			order:1;
		}

		#form-busca{
			order:2;
		}

		.mbl-controls{
			display:none;
		}

	}

	&.index{
		padding-bottom:6px;

		.topo-bar{

			&:before,
			&:after{
				content:'';
				display:block;
				height:6px;
				position:absolute;
				top:100%;
			}

			&:before{
				background-color:#bfc7d4;
				left:0;
				width:((1065 * 100) / 1920) * 1%;
			}

			&:after{
				right:0;
				background-color:$theme;
				width:((855 * 100) / 1920) * 1%;
			}

		}

	}

}