.menu-topo{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;

	& > li{
		display:block;

		& > a{
			font-size:16px;
			@extend .lato-reg;
		}

		&.active > a,
		&:hover > a{
			color:#FFFFFF;
			background-color:$theme;
			@extend .lato-bld;
			border-color:darken($theme,10%);
		}

	}

	@include media-breakpoint-up(xl){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;
		min-width:((540 * 100) / 1110) * 1%;

		& > li{

			& > a{
				display:inline-block;
				padding:16px 8px;
			}

		}

	}

	@include media-breakpoint-down(lg){
		margin-bottom:20px;

		& > li{

			& > a{
				display:block;
				padding:10px 15px;
				border:1px solid transparent;
			}

		}

	}

}