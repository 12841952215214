@font-face {
    font-family: 'latolight';
    src: url('#{$fonts}/lato_light/lato-light-webfont.eot');
    src: url('#{$fonts}/lato_light/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_light/lato-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_light/lato-light-webfont.woff') format('woff'),
         url('#{$fonts}/lato_light/lato-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_light/lato-light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot');
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff') format('woff'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato-bold';
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot');
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff') format('woff'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato-black';
    src: url('#{$fonts}/lato-black/lato-black-webfont.eot');
    src: url('#{$fonts}/lato-black/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato-black/lato-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato-black/lato-black-webfont.woff') format('woff'),
         url('#{$fonts}/lato-black/lato-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato-black/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratlight';
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot');
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latomedium';
    src: url('#{$fonts}/lato_medium/lato-medium-webfont.eot');
    src: url('#{$fonts}/lato_medium/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.woff') format('woff'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_medium/lato-medium-webfont.svg#latomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}