.mbl-controls{
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	background:$topo-bar;

	&.fixo{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		z-index:998;
		box-shadow:0 0 8px rgba(#000000, 0.5);
	}

	.bg-menu{
		width:100%;
		background-color:rgba(#000000, 0.5);
		position:fixed;
		top:0;
		left:0;
		opacity:0;
		transition:opacity 0.6s ease-out;
	}
	
	.logo-mobile{
		max-width:35%;
		width:120px;
		padding:10px;
	}

	#toggleMenu{
		font-size:28px;
		background-color:$topo-bar;
		border:none;
		outline:none;
		padding:10px;
		transition:all 0.6s ease-in;
		box-shadow:0 0 0 $topo-bar;
		cursor:pointer;

		&:hover,
		&:focus{
			background-color:darken($topo-bar, 15%);
			color:$theme;
			box-shadow:0 0 0 4px rgba(darken($topo-bar, 15%), 0.5);
		}

	}

}