.rodape{
	background-color:#395d91;
	color:#ffffff;

	.menu-rodape{

		&:before{
			top:100%;
		}

	}

	.copyright{
		font-size:12px;
		text-align:center;

		.d-md-flex{
			justify-content:center;
			position:relative;
			padding:6px 0;

			&:before{
				top:0;
			}

		}

	}

	.copyright .borda-dahora,
	.menu-rodape{

		&:before{
			content:'';
			display:block;
			position:absolute;
			left:0;
			width:100%;
			max-width:1185px;
			height:1px;
			background-color:#FFFFFF;
		}

	}

	@include media-breakpoint-up(lg){

		.copyright .borda-dahora,
		.menu-rodape{

			&:before{
				min-width:1172px;
				left:calc(0% - 30px)
			}

		}

	}

}