.dropdown-custom{
	cursor:pointer;

	&.active{

		.fa{
			transform:rotate(180deg);
			font-weight:bold;
		}

	}

	& > a:not([href]):not([tabindex]):hover,
	& > a:not([href]):not([tabindex]):focus{
		color:#FFFFFF;
	}

	& > a{

		.fa{
			font-size:12px;
			transition:transform 0.4s linear;
		}

	}

	.dropdown-menu{
		margin:0;
		border:none;
		padding:0;
		min-width:220px;
		border-radius:0;
		float:none;
		position:relative;
		top:0;

		& > li{
			display:block;

			&:nth-child(odd){

				& > a{
					background-color:#eaebef;
				}

			}

			&:nth-child(even){

				& > a{
					background-color:#FFFFFF;
				}

			}

			& > a{
				display:block;
				font-size:12px;
				padding:10px 15px;
				@extend .lato-reg;
			}

		}

	}

	@include media-breakpoint-up(lg){

		.dropdown-menu{
			position:absolute;
			top:100%;

			& > li{

				& > a{
					padding:12px 8px;
				}

			}

		}

	}

	@include media-breakpoint-down(lg){


		& > a{
			display:flex !important;
			flex-wrap:wrap;
			justify-content:space-between;

			.box-icon{
				width:10%;
				text-align:center;

				& > .fa{
					font-size:20px;
				}

			}

		}


	}

}