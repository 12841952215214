// Margins
@for $i from 0 through 20{
	$m: $i * 5;

	@include margin-variant($m);
}

lazyimage{
	display: block;

	.canvas-container{
		position: relative;
	}

	.cavas{
		position: absolute;
		width: 100%;
		height: 100%;
		left:0;
		top:0;
	}
}

.figure-controlada{
	margin-bottom:0;
}

.logo{
	@extend .figure-controlada;
}

.container{
	
	@include media-breakpoint-up(lg){
		padding-left:30px;
		padding-right:30px;
	}

}

.icon-svg{
	display: inline-block;
	border:none;
	margin:0;
	vertical-align: middle;
}


.gv8-icon{
	width: toEm(58,12);
	height: toEm(18,12);
	margin-left:10px;
}

.mgb-150{
	margin-bottom:150px;
}

.owl-carousel{

	.owl-item{

		img{
			display:inline-block;
			width:inherit;
		}

	}

	&.owl-carousel-padding{
		padding-left:15px;
		padding-right:15px;
	}

}

.owl-controls{
	position:relative;

	.owl-stage-outer{
		z-index:10;

		.owl-item{
			position:relative;
			z-index:20;
		}

	}

	.owl-custom{
		position:absolute;
		top:0;
		left:0;
		display:flex;
		justify-content:center;
		align-items:center;
		width:100%;
		height:100%;

		.owl-nav{
			width:1170px;
			@include clearfix();

			& > *{
				position:relative;
				z-index:30;
			}

		}

	}

}

.embed-responsive-1110x525{

	&:before{
		padding-top:((525 * 100) / 1110) * 1%;
	}

}


#banner.carousel-fade-out{

	.carousel-item{
		overflow: hidden;
	}

	.clone{
		position: absolute;
		width:100%;
		left:0;
		top: 0;
		z-index: 2;
	}

	.active .clone{
		animation: fadeOut 0.6s linear forwards
	}
}