.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn{
	border-radius:0;
	font-size:14px;

	&.btn-theme{
		color:#FFFFFF;
		@include button-variant($theme,$theme);
	}

	&.btn-outline-theme{
		@include button-outline-variant($theme,#FFFFFF,$theme);
		border-width:2px;
		transition:all 0.6s linear;
		box-shadow:0 0 0 0 #000000;

		&:hover,
		&:focus{
			border-color:#FFFFFF;
			box-shadow:0 0 12px 2px rgba(#000000, 0.5);
		}

	}

	&.btn-outline-theme-diferente{
		@include button-outline-variant($theme,$theme,#ededed);
		background-color:#ededed;
		border-width:2px;
		transition:all 0.6s linear;
		box-shadow:0 0 0 0 #000000;

		&:hover,
		&:focus{
			border-color:$theme;
			box-shadow:0 0 12px 2px rgba(#000000, 0.5);
		}

	}

	&.btn-white{
		@include button-variant(#FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF);
		color:$theme;

		&:hover,
		&:focus,
		&:not(:disabled):not(.disabled):active{
			color:$theme;
		}

	}

	&.btn-sm{
		padding:8px 10px;
		min-width:109px;
	}

	&.btn-lg{
		min-width:134px;
		padding:12px 15px;
	}

}