.header-section{
	padding-left:10px;
	margin-bottom:18px;

	.title{
		margin-bottom:0;
		font-size:(36 * 0.8) * 1px;
		@extend .lato-bld;
		color:$theme;
	}

	&.header-lato-itc{

		.title{
			font-family: 'latolight';
			font-style:italic;
		}

	}

	&.header-white{

		.title{
			color:#FFFFFF;
		}

	}

	&.header-icone{
		text-align:center;
		
		img{
			margin-bottom:20px;
		}

	}

	@include media-breakpoint-up(md){

		.title{
			font-size:36px;
		}

		&.header-icone{

			display:flex;
			flex-wrap:wrap;
			align-items:center;
			flex-wrap:wrap;

			img{
				margin-bottom:0;
			}

			.title{
				margin-left:18px;
			}

		}

	}

}