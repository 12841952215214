.contato-rodape{
	text-align:center;
	font-size:14px;
	@extend .lato-lgt;
	margin-bottom:30px;

	.fa{
		font-size:20px;
		margin-bottom:12px;
	}

	.title{
		margin-bottom:2px;
		font-size:13px;
		@extend .lato-bld;
	}

}