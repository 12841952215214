.article-servicos{
	margin-bottom:15px;
	
	.article-content{

		.parte-texto,
		.parte-btn{
			font-size:16px;
		}

		.parte-texto{
			background-color:#f5f5f5;
			@extend .lato-med;
			padding:13px 30px;
			color:#193f70;
			transition:all 0.6s linear;
			width:100%;
		}

		.parte-btn{
			@extend .lato-bld;
			padding:12px 20px;
			width:100%;
		}

	}

	&:hover{

		.article-content{

			.parte-texto{
				background-color:#6f6f6f;
				color:#FFFFFF;
			}

		}

	}

	@include media-breakpoint-up(lg){

		.article-content{
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;

			.parte-texto{
				width:(((955 * 100) / 1111) * 1%);
			}

			.parte-btn{
				width:(((156 * 100) / 1111) * 1%);
			}

		}

	}

}