.media-legal{
	text-align:center;
	margin-bottom:10px;
	color:$theme2;

	.icon,
	.fa{
		margin-bottom:4px;
		display:block;
		font-size:20px;
	}

	.desc{
		font-size:13px;
		@extend .lato-lgt;
		margin-bottom:6px;

		&.desc-mgb-15{
			margin-bottom:15px;
		}

	}

	&.media-mgb-20{
		margin-bottom:20px;
	}

	&.icone-sem-mgb{

		.icon,
		.fa{
			margin-bottom:0;
		}

	}

	&.icone-mgb-15{

		.icon,
		.fa{
			margin-bottom:15px;
		}

	}


	.fa-map-marker,
	.fa-clock-o,
	.fa-whatsapp{
		font-size:25px;
	}

}