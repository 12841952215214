.card-produtos{
	max-width:350px;
	margin-bottom:30px;
	
	.figure-card{
		position:relative;
		margin-bottom:10px;

		img{
			transition:transform 0.6s linear;
		}

		.btn{
			position:absolute;
			bottom:0;
			right:0;
			@extend .lato-bld;
			z-index:10;
		}

	}

	.card-desc{
		font-size:16px;
		color:$theme;
		@extend .lato-reg;
	}

	@include media-breakpoint-up(md){

		.figure-card{
			transition:all 0.6s linear;
			min-width:350px;
			min-height:336px;


			img{
				max-width:308px;
				transition:all 0.6s linear;
				max-height:291px;
			}

		}

		&:hover{

			.figure-card{
				
				img{
					max-width:350px;
					max-height:336px;
				}

			}

		}

	}

}