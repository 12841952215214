.card-circle{
	text-align:center;
	margin-bottom:35px;

	.figure-header{
		margin-bottom:10px;

		img{
			border-radius:100%;
			overflow:hidden;
		}

		.card-desc{
			font-size:16px;
			@extend .lato-reg;
			color:$theme;
		}

	}

}