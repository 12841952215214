.subtitle-section{
	margin-bottom:0;
	font-size:24px;
	color:inherit;
	@extend .lato-bld;

	&.subtitle-diferente{
		font-size:13px;
		@extend .mnt-lgt;
	}
}