.topo{
	padding-top:8px;

	.topo-info{

		.dados{
			padding-right:17px;
		}

	}

	.topo-bar{
		background-color:$topo-bar;
	}

}