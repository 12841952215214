.form-fale{

	form{

		.input-group{
			margin-bottom:20px;

			.form-control,
			.input-group-prepend .input-group-text{
				border-radius:0;
			}

			.form-control{
				resize:none;
			}

		}

	}

}

.form-fale-estilos{

	form{

		.form-control{
			color:$theme;
			background-color:#FFFFFF;
			font-size:14px;
			@extend .lato-lgt;
			font-style:italic;
			box-shadow:none;

			@include placeholdIt{
				color:$theme;
			}

		}

		.btn{
			font-size:16px;
			@extend .lato-bld;
		}

	}

}